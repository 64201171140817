
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');
* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.App {
  display: flex;
  height: 100vh;
}

.Error-page {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Error-page i {
  color: #818181;
}