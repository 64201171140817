.mtb-selector-container {
  cursor: pointer;
  user-select: none;
  border-radius: 16px;
  font-family: Outfit;
 
}

.mtb-selector-value {
  display: flex;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 8px;
  justify-content: space-between;
  height: 44px;
  align-items: center;
  font-family: Outfit;
  color: #777777;
  padding-left: 21px;
  padding-right: 16px;
  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15) inset;
}
.mtb-selector-value-disabled {
  display: flex;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 8px;
  justify-content: space-between;
  height: 44px;
  align-items: center;
  font-family: Outfit;
  color: #777777;
  padding-left: 21px;
  padding-right: 16px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15) inset;
  opacity: 0.6;
  cursor: not-allowed;
}
.mtb-selector-options {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 10;
  overflow: auto;
  max-height: 150px;
  border-radius: 8px;
}

.mtb-selector-option {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mtb-selector-option:hover {
  background-color: #bada55;
  border-radius: 8px;
  color: white;
  font-family: Outfit;
}

.mtb-selector-square {
  border-radius: 0px;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  outline:auto;
  outline-color: #bababa;
}
.Helper-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  width: 100%;
  bottom:10px;
  color: red;
  font-size: 12px;
}