.leftMainText {
  color: var(--base-light, #fff);
  text-align: left;
  font-family: Outfit, sans-serif;
  font-size: 30px;
  line-height: 48px;
  font-weight: 700;
  position: absolute;
  left: 5%;
  top: 20%;
  white-space: nowrap;
}


.button {
  border-radius: 16px;
  width: 10px;
  flex: 1;
  background-color: var(--gray-200-color);
}

.buttonActivated {
  background-color: var(--primary-orange-color);
}

.button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 30px;
  cursor: pointer;
  border: none;
  color: var(--base-light);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 50px;
  width: 10%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.successText {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  font-family: "Outfit";
  font-weight: 700;
  font-size: 32px;
}