.HomeView {
  display: flex;
  flex: 1;
  background: #FFF;
}

.Sidebar {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  background: #FFF;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
  width: 64px;
  align-items: center;
  z-index: 2;
}

.Sidebar-expanded {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  background: #FFF;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
  width: 250px;
  align-items: center;
  z-index: 2;

}

.Sidebar img {
  display: flex;
  width: 40px;
  padding: 8px;
  justify-self: center;
}

.Sidebar .Menu {
  display: flex;
  flex: 1;
  /* overflow: auto; */
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.Sidebar-expanded .Menu {
  display: flex;
  flex: 1;
  /* overflow: auto; */
  flex-direction: column;
  justify-content: space-around;
}

.Sidebar .Menu::-webkit-scrollbar {
  display: flex;
  flex-direction: column;
}

.Sidebar .Menu-option {
  display: flex;
  /* padding: 8px; */
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: #FFF;
  text-decoration: none;
  background-color: #FFF;
  border: 1.1px solid #F2F2F7;
  width: 32px;
  height: 32px;
  justify-content: center;
  box-shadow: 0px 4.3857598304748535px 4.3857598304748535px 0px #00000014;
  padding: 8px;


}

.Sidebar .Menu-option-expanded {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  /* gap: 12px; */
  border-radius: 12px;
  background: #FFF;
  text-decoration: none;
  background-color: #FFF;
  border: 1.1px solid #F2F2F7;
  width: 32px;
  height: 32px;

  justify-content: center;
  box-shadow: 0px 4.3857598304748535px 8.771519660949707px 0px #32324702;
  padding: 8px;
}

#Menu-option-logo {
  display: flex;
  padding: 8px;

  gap: 12px;
  background: #FFF;
  text-decoration: none;
  background-color: #FFF;
  /* border: 1.1px solid #F2F2F7; */
  width: 48px;
  height: 48px;
  /* box-shadow: 0px 4.3857598304748535px 8.771519660949707px 0px #32324702; */
  justify-content: flex-start;
  /* box-shadow: 0px 4.3857598304748535px 4.3857598304748535px 0px #00000014; */
  padding: 4px;
}

.Sidebar,
.Sidebar-expanded .Menu-option span {
  color: #626262;
  font-size: 14px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;
}

.Sidebar a.Menu-option.active {
  background: #F5EAEC;


}

.Sidebar .Menu-option:hover {
  background: rgba(211, 211, 224, 0.1);
}

.Sidebar .Menu-option.active path {
  fill: #fff;
}

.Sidebar .Menu-option.active span {
  color: #fff;

}

.View {
  /* padding: 59px 0px 32px 0px; */
  /* display: flex; */
  flex: 1;
  /* overflow: auto; */
  /* margin-top: 40px;; */
}

.tableActions {
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.Topbar {
  position: fixed;
  height: 80px;
  background: white;
  border-bottom: 1px #aaa solid;
  width: 100%;
  z-index: 1;
}

.Sidebar-expanded a.Menu-option-expanded,
a.Menu-option {
  display: flex;
  flex-direction: row;
  color: #626262;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: flex-start;
  text-decoration: none;
  align-items: center;
  padding: 2px;

}

.Sidebar-expanded a.Menu-option-expanded {
  min-height: 48px;
}

.Sidebar-expanded a.Menu-option {
  background-color: #F8F9FE;
  border-radius: 10px;
  padding: 5px 2px;
  min-height: 48px;
}