.MTB-input {
  display: flex;
  align-items: center;
  height: 80px;
  flex-direction: column;
  gap: 2px;
}

.MTB-input>img {
  align-self: flex-start;
  margin-right: 20px;
  padding: 12px 0;
}

.MTB-input>span {
  align-self: flex-start;
  margin-right: 12px;
  padding: 12px 0;
}

.MTB-input input {
  display: flex;
  padding: 5px 16px 5px 36px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15) inset;
  color: #959595;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal;
  align-self: normal;
  text-align: left;
  padding-right: 10px;
  border: 1px solid #BABABA;
  height: 48px;
  font-family: Outfit;
}

.MTB-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.MTB-input input[type=number] {
  -moz-appearance: textfield;
}

.MTB-input.start {
  flex-direction: unset;
}

.MTB-input.start input {
  text-align: start;
  width: -webkit-fill-available;
}

div.MTB-input input:focus-visible {
  outline: 3px solid #E090AD;
  color: #403F3F;
}

.MTB-input input:disabled {
  background: #DADADA;
}

.Helper-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  width: 40%;
}

.MTB-input .Helper-text {
  display: flex;
  padding: 0px 8px;
  align-items: center;
  justify-self: flex-end;
  gap: 8px;
}

.MTB-input .Helper-text span {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70%;
  display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

}


.MTB-input.warning span,
.MTB-input.warning input {
  color: #FF3D3D;
}


.MTB-input.warning input {
  outline: 1px solid #FF3D3D;
}

.MTB-input.success span,
.MTB-input.success input {
  color: #006975;
}

.MTB-input.success input {
  outline: 1px solid #006975;
}

.MTB-input.info span,
.MTB-input.info input {
  color: #959595;
}

.MTB-input.info input {
  outline: 1px solid #959595;
}


.input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-width: 50%;
}

.input-container input {
  width: calc(100% - 30px);
  padding: 8px;
  padding-right: 50px;
  padding-left: 24px;
}

.view-icon {

  right: 20px;
  height: 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}


.Helper-text img {
  margin-right: 4px;
  width: 12px;
}

.input-container-category {
  background-color: #F4F4F4;
  width: 100%;
  display: flex;
  border-radius: 16px;
}

.MTB-category-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  font-size: 12px;
}

.input-container-category input {
  display: flex;
  padding: 4px 16px 4px 32px;
  border-radius: 8px;
  background: #F4F4F4;
  color: #959595;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal;
  align-self: normal;
  text-align: left;
  padding-right: 10px;
  border: 1px solid #fff;
  height: 48px;
  font-family: Outfit;
  width: 100%;
}