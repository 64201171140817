.view {
  background: url("../../assets/subsImage.png") repeat center center fixed;
  /* background-size: repeat; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 2%;
}
h1 {
  color:#F09925;
  font-size: 22px;
}
.titleContainer {
  display: flex;
  align-items: center;
}
.tableContainer {
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  height: 90%;
  background: rgba(253, 253, 253, 0.3);
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  align-content: space-around;
}
.contentContainer {
  width: 95%;
}
.tableRow {
  background-color: transparent !important;
  font-family: Outfit;
}

.innertableContainer {
  background-color: transparent !important;
  font-family: Outfit;
  box-shadow: none;
}


.tableActions {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
}

.baseButton {
  margin-right: 10px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  font-weight: bold;
}

.exportButton {
  background: none;
  color: #F09925;
  border: 1px solid #F09925;
  border-radius: 16px;
}

.createEventButton {
  background: #F09925;
  color: white;
  border: none;
  border-radius: 16px;
}
.buttonsContainer {
  display: flex;
}

.tableHeader {
  font-weight: bold;
}
.input {
  border: none;
}
.inputContainer span {
  color: #8F8F8F;
}
.inputContainer {
  display: flex;
  background: #FCFCFC;
  padding: 10px 10px;
  border-radius: 10px;
  box-shadow: 0px 4.679279327392578px 9.358558654785156px 0px #32324702;
  box-shadow: 0px 4.679279327392578px 4.679279327392578px 0px #00000014;
  min-width: 250px;
  min-height: 28px;
}

input:focus{
  outline: none;
}

.advertisementImg {
  width: 100%;
  display: flex;
  justify-content: center;
}

.paginatorContainer {
  width: 15px;
  border: none;
  padding-left: 3px;
  box-sizing: border-box;
}

.outfitFamily {
  font-family: Outfit;
}