.mtb-category-selector {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  padding: 20px;

  overflow: auto;
  height: 60vh;
  margin-right: -17px;

}

.scroll-wrapper {
  overflow: hidden;
  height: 100%;
  margin-bottom: 200px;
}