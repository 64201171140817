.view {
  background: url("../../assets/Rectangle.png") repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2%;
}
h1 {
  color:#F09925;
  font-size: 22px;
}
.titleContainer {
  position: absolute;
  left: 170px;
  top: 10px;
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.tableContainer {
  margin-top:-5px;
  border-radius: 10px;
  padding: 15px;
  /* box-sizing: border-box; */
  width: 97%;
  height: 97%;
  background: rgba(253, 253, 253, 0.3);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}
.contentContainer {
  width: 88%;
  height: 88%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.eventsContainer {
  display: flex;
}
.eventTypeContainer {
  width: 170px;
  height: 170px;
  border-radius: 17px;
  background-color: white;
  /* margin-right: 10px; */
}
.baseButton {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 18px 25px;
  font-weight: bold;
  font-size: 17px;
  margin: 40px 0 0 0;
  height: 60px;
  margin: 0;
  border: 2px solid transparent;
  color: white;
  border-radius: 30px;
  /* width: 110px; */
  height: 40px;
  padding: 10px 20px;
  display: flex;
}
.createEventButton {
  background: #F09925;
  color: white;
  border: none;
  border-radius: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.title {
  font-family: Outfit;
  font-size: 18.09px;
  font-weight: 400;
  line-height: 40.92px;
  align-self: flex-start;
  margin-bottom: 20px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed
}
.input {
  border: none;
  width: 100%;
  height: 100%;
  background: #FCFCFC;
}
.inputContainer span {
  color: #8F8F8F;
}
.inputContainer {
  display: flex;
  background: #FCFCFC;
  padding: 25px 25px;
  border-radius: 10px;
  box-shadow: 0px 4.679279327392578px 9.358558654785156px 0px #32324702;
  box-shadow: 0px 4.679279327392578px 4.679279327392578px 0px #00000014;
  /* min-width: 250px; */
  min-height: 38px;
  box-sizing: border-box;
}
.inputError {
  border: 1px solid red;
}

input:focus{
  outline: none;
}
textarea:focus {
  outline: none;
}


.primary-color {
  color: #00AAD6;
}

.secundary-color {
  color: #A1A1AA;
}

.primary-background {
  background: #00AAD6;
}

.white-background {
  background: white;
}

.white-color {
  color: white;
}


.outfit-font {
  font-family: Outfit;
  font-weight: 400;
  line-height: 18px;
  font-size: 16px;
  /* color: #514F4F; */
}
.buttonsContainer {
  background-color: white;
  padding: 6px 8px;
  border-radius: 100px;
  box-sizing: border-box;
  margin-left: 95px;
}
.contentSelector:first-child {
  margin-right: 10px;
}
.contentSelector {
  border: none;
  padding: 7px 10px;
  border-radius: 100px;
}
.buttonAbsolute {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
  border: 2px solid transparent;
  color: white;
  border-radius: 30px;
  /* width: 110px; */
  height: 40px;
  padding: 10px 20px;
  display: flex;
}
.buttonAbsolute:hover {
  background-color: white;
  color: #00AAD6;
  border: 2px solid #00AAD6;
}
.buttonAbsolute2 {
  /* position: absolute;
  top: 10px;
  right: 10px; */
  margin: 0;
  border: 2px solid transparent;
  color: white;
  border-radius: 30px;
  /* width: 110px; */
  height: 40px;
  padding: 10px 15px  ;
  display: flex;
  background-color: white;
  color: #00AAD6;
  border: 2px solid #00AAD6;
}
.buttonAbsolute2:hover {
  background-color: #00AAD6;
  color: white;
  border: 2px solid #00AAD6;
}
.sub-content-container {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
}
.left-container {
  justify-content: center;
}
.subcategory-container {
  box-sizing: border-box;
  background: #F4F4F4;
  border: 1px solid #514F4F;
  width: Fill (85px)px;
  height: Hug (40px)px;
  padding: 12px 16px;
  border-radius: 14px;
  margin-right: 10px;
}
.media-container {
  width: 80%;
  height: 90%;
  background: url("../../assets/unsplash-business.png") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
}

.sub-media-container {
  width: 90%;
  height: 42%;
  background: white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}