.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.contentContainer {
  display: flex;
  background-color: white;
  padding: 20px;
  border-radius: 25px;
  max-width: 500px;
  width: 120%;
  height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.baseButton {
  margin-right: 10px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  font-weight: bold;

}

.acceptButton {
  background: none;
  color: var(---gray-500-color);
  border: 1px solid var(---gray-500-color);
  border-radius: 32px;
  width: 100%;
  justify-content: center;

}

.cancelButton {
  background: var(--primary-blue-color);
  color: white;
  border: none;
  border-radius: 32px;
  width: 100%;
  justify-content: center;
  font-family: "Poppins";
}

.modalText {
  justify-content: center;
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(---gray-500-color);
}

.spanText {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--primary-blue-color);
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Outfit;
  font-size: 32px;
  font-weight: 700;
  gap: 16px;
}

.modalHeader {
  flex: 1;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  text-align: center;
}

.modalBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Outfit;
  font-size: 24px;
  width: 80%;
  line-height: 12px;
}

.modalFooter {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  flex-direction: row;
}