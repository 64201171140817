@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');
body {
  margin: 0;
  font-family:
  'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Figma Styles of your File */
:root {
  /* Colors */
  --base-light: #FFF;
  --gray-200-color: #D6D6D6;
  ---gray-500-color: #929191;
  --primary-orange-color: #F09925;
  --primary-white-color: #FFFFFF;
  --primary-blue-color: #00AAD6;
  --gradient: linear-gradient(-90deg,
      rgba(212, 243, 255, 1) 0%,
      rgba(182, 240, 254, 1) 51.72863006591797%,
      rgba(255, 247, 226, 1) 99.15677905082703%);
  --fdsa: linear-gradient(0deg,
      rgba(185, 241, 255, 1) 0%,
      rgba(255, 253, 246, 1) 100%);
  --vertical: linear-gradient(0deg,
      rgba(207, 245, 255, 1) 0%,
      rgba(255, 255, 255, 1) 49.67639744281769%,
      rgba(255, 251, 240, 1) 100%);
  --verticaltowhite: linear-gradient(0deg,
      rgba(191, 244, 255, 1) 0%,
      rgba(255, 255, 255, 1) 100%);
      
  /* Fonts */
  /* Effects */
  --shadow-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}