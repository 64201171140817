.view {
  background: url('../../assets/comingsoonBackground.jpeg') repeat center center fixed;
  background-size: cover;

  color: #F09925;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

h1 {
  color: #F09925;
  font-size: 22px;
}

.overlay:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom, var(--primary-orange-color), var(--primary-blue-color));
  opacity: 0.6;
  z-index: 300;
}

.overlay:after {
  backdrop-filter:
    blur(1px) grayscale(.9) contrast(.2) sepia(.1) hue-rotate(180deg);
  content: '';
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.overlay {
  position: relative;
  display: block;
}

.overlay .content {
  position: relative;
  z-index: 400;
}

.img {
  display: flex;
  align-self: center;
  border-radius: 20px;
  position: relative;
  z-index: 1000;
  width: 500px;
  margin-bottom: -300px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contentContainer h1 {
  font-size: 22px;
  margin-bottom: 10px;
}

.contentContainer p {
  font-size: 16px;
  margin-bottom: 10px;
}

.text {
  z-index: 1000;
  font-family: 'Outfit';
  color: white;
}

.title {
  font-family: 'Outfit';
  font-size: 72px;
  font-weight: 700;
  text-shadow: 0px 6px 2px #00000026;
}

.subTitle {
  font-family: 'Outfit';
  font-size: 36px;
  font-weight: 400;
  text-shadow: 0px 1px 2px 0px #0000004D;
}